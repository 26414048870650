import React from 'react'
import Link from 'gatsby-link'
import Header from '../components/Header'
import Contacts from '../components/Contacts'
import Services from '../components/Services'
import SS from '../components/Services/ss'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Helmet from 'react-helmet'

const VVSPage = () => (
  <div>
    <Helmet
    title="Verslo sistemų programavimas"
    />
      <div className="holder" css={{padding: '50px 0'}}>
          <Grid fluid>
            <Row>
              <Col xs={12} lg={12}>
              <section className="service">
              <br />
                <br />
                <h1>UAB „Wise Monks” verslo sistemų pristatymas užsienio rinkoms</h1>
                <br />
                <p>UAB „Wise Monks“ įgyvendina projektą „UAB „Wise Monks” verslo sistemų pristatymas užsienio rinkoms“.</p>
                
                <p>UAB "Wise Monks" siekia sparčiai augančioje verslo sistemų kūrimo/diegimo ir valdymo rinkoje įsitvirtinti ir išplėsti klientų ratą užsienio rinkose. Tarptautinės parodos sutraukia daugelį įmonei patrauklių verslo sektorių, tokių kaip nekilnojamo turto agentūrų specialistų, kurių sprendimai dėl verslo valdymo yra dažnai lemiami įmonių veikloje. Įmonės kuriamos sistemos ir siūlomos paslaugos bus taip pat pristatomi projekto veiklose. Objektyviai vertinant, savo produkcijos pristatymas parodose yra viena geriausių priemonių prekės ženklo ir produkcijos žinomumo formavimui. Taip pat, pasirinktos parodos prisidės prie UAB „Wise Monks“ eksporto didinimo į strategines įmonės rinkas.
                </p>

                
                <p>
                Projektas finansuojamas UAB „Wise Monks“ nuosavomis lėšomis ir Europos regioninės plėtros fondo lėšomis pagal 2014–2020 m. Europos Sąjungos fondų investicijų veiksmų programos 3 prioriteto „Smulkiojo ir vidutinio verslo konkurencingumo skatinimas“ įgyvendinimo priemonę Nr. 03.2.1-LVPA-K-801 „Naujos galimybės LT“.
                </p>


                <p><strong>Projekto pavadinimas</strong> – „UAB „Wise Monks” verslo sistemų pristatymas užsienio rinkoms“.</p>
                <p><strong>Projekto vykdytojas</strong> – UAB „Wise Monks“.</p>
              </section>
              </Col>
            </Row>
          </Grid>
        
    </div>
    <Contacts />
  </div>
)

export default VVSPage

